import './App.css';
import Cards from './pages/Cards';
import CardDetail from './pages/CardDetail';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import EditCard from './pages/EditCard';
import Home from './pages/Home';
import CreateCard from './pages/CreateCard';
import Header from './components/Header';
import EditHome from './pages/EditHome';
import { Helmet } from 'react-helmet'
import EditCommentText from './pages/EditCommentText';
import SignIn from './pages/SignIn';
import AdminCommentList from './pages/AdminCommentList';

function App() {

    return (
        <div className='App'>
            <Helmet>
                <title>Gespreksstarters | Potential to Teach</title>
            </Helmet>
            <Header />
            <Router>
                <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/cards' exact component={Cards} />
                    <Route path='/card/:id' exact component={CardDetail} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/edit/:id" exact component={EditCard} />
                    <Route path="/add" exact component={CreateCard} />
                    <Route path="/dashboard/edit-home" exact component={EditHome} />
                    <Route path="/dashboard/edit-comment-text" exact component={EditCommentText} />
                    <Route path="/signin" exact component={SignIn} />
                    <Route path="/dashboard/comments" exact component={AdminCommentList} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;

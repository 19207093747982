import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUrlById } from '../config/firestore';

const CardThumbnail = (props) => {
    const [cardImg, setCardImg] = useState();
    const [isBrokenImg, setIsBrokenImg] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await getUrlById(props.card.id);
            setCardImg(response);
        })();
    }, []);

    return (
        <Link to={'/card/' + props.card.id} className="card-thumb">
            {(cardImg && !isBrokenImg) ? (
                <img src={cardImg} className="card-thumb__img" onError={() => setIsBrokenImg(true)} />
            ) : (
                <div className="card-thumb__content">
                    <h2 className="card-thumb-title">
                        {props.card.data.title}
                    </h2>
                </div>
            )}
        </Link>
    );
};
export default CardThumbnail;

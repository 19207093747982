import React, { useEffect, useState } from 'react';
import {
    getCardById,
    getCommentConfirmationText,
    getCommentsByCardId,
    getUrlById,
    likeCardById,
    storeComment,
} from '../config/firestore';
import Comment from '../components/Comment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as faLikeSolid } from '@fortawesome/free-solid-svg-icons';
import { CommentList } from '../components/CommentList';

const fetchLikesFromStorage = () => {
    let storage = localStorage.getItem('cardLikes');
    storage =
        storage && storage !== 'undefined' ? JSON.parse(storage) : storage;
    return storage?.length ? storage : [];
};

const CardDetail = (props) => {
    const [card, setCard] = useState();
    const [cardId, setCardId] = useState();
    const [cardUrl, setCardUrl] = useState();

    const [userLikes, setUserLikes] = useState(fetchLikesFromStorage());
    const [likes, setLikes] = useState();

    const [comments, setComments] = useState();

    const [newComment, setNewComment] = useState();
    const [commentUser, setCommentUser] = useState();
    const [commentEmail, setCommentEmail] = useState();

    const [showCommentModal, setShowCommentModal] = useState(false);
    const [commentModalText, setCommentModalText] = useState();

    useEffect(() => {
        fetchCard();
        fetchComments();
        fetchCommentModalText();
        // loadDeviceLikes();
    }, []);

    useEffect(() => {
        localStorage.setItem('cardLikes', JSON.stringify(userLikes));
    }, [userLikes]);

    const fetchCommentModalText = async () => {
        const data = await getCommentConfirmationText();
        setCommentModalText(data.data().text);
    };

    const fetchCard = async () => {
        const data = await getCardById(props.match.params.id);
        setLikes(data.data()?.likes || 0);
        setCard(data.data());
        setCardId(data.id);
        setCardUrl(await getUrlById(data.id));
    };

    const fetchComments = async () => {
        const data = await getCommentsByCardId(props.match.params.id);
        const list = [];
        data.forEach((doc) => list.push({ data: doc.data(), id: doc.id }));
        setComments(list);
    };

    const createComment = () => {
        storeComment(commentUser, commentEmail, newComment, cardId);
        setShowCommentModal(false);
        fetchComments();
        setNewComment('');
        setCommentUser('');
        setCommentEmail('');
    };

    const likeCard = () => {
        let newLikeCount = likes;

        if (userLikes.includes(cardId)) {
            setUserLikes((prev) =>
                userLikes.filter((cardId) => cardId !== cardId)
            );
            newLikeCount--;
        } else {
            setUserLikes((prev) => {
                return userLikes?.length ? [...userLikes, cardId] : [cardId];
            });
            newLikeCount++;
        }

        setLikes(newLikeCount);
        likeCardById(cardId, newLikeCount);
    };

    return (
        <div className="card-detail">
            <div className="card-detail__card">
                <img
                    className="card-detail__image"
                    src={cardUrl ? cardUrl : 'loading'}
                    alt={cardUrl ? cardUrl : 'loading'}
                />
                <div className="card-detail__likes">
                    <FontAwesomeIcon
                        className="like-icon card-detail__like-icon"
                        icon={
                            userLikes.includes(cardId)
                                ? faLikeSolid
                                : faThumbsUp
                        }
                        onClick={likeCard}
                    />
                    <div className="card-detail__likes-count">{likes}</div>
                </div>

                {card?.content && (
                    <div className="card-detail__extra">
                        <h2>Meer over dit onderwerp</h2>
                        <p dangerouslySetInnerHTML={{ __html: card.content }} />
                    </div>
                )}
            </div>

            {showCommentModal && (
                <div className="deletion-modal">
                    <h4>Weet u zeker dat u dit bericht wil delen?</h4>
                    <p
                        className="deletion-modal-text post-confirm-text"
                        dangerouslySetInnerHTML={{
                            __html: commentModalText,
                        }}
                    />
                    <div className="deletion-modal-button-group">
                        <button
                            className="deletion-modal-button deletion-modal-cancel"
                            onClick={() => setShowCommentModal(false)}
                        >
                            Annuleren
                        </button>
                        <button
                            className="deletion-modal-button deletion-modal-confirm post-confirm-button"
                            onClick={() => createComment()}
                        >
                            Delen
                        </button>
                    </div>
                </div>
            )}

            <div className="comment-section">
                {card?.commentsEnabled ? (
                    <>
                        <div className="create-comment">
                            <h4 className="create-comment__title">
                                Jouw idee of praktijkvoorbeeld?
                            </h4>
                            <div className="create-comment__value-container">
                                <label
                                    htmlFor="comment-name"
                                    className="create-comment__label"
                                >
                                    Naam en voornaam:
                                </label>
                                <input
                                    type="text"
                                    id="comment-name"
                                    onChange={(u) =>
                                        setCommentUser(u.target.value)
                                    }
                                    placeholder="Je naam..."
                                    className="create-comment__input"
                                    value={commentUser || ''}
                                />
                            </div>
                            <div className="create-comment__value-container">
                                <label
                                    htmlFor="comment-email"
                                    className="create-comment__label"
                                >
                                    E-mailadres:
                                </label>
                                <input
                                    type="text"
                                    id="comment-email"
                                    onChange={(u) =>
                                        setCommentEmail(u.target.value)
                                    }
                                    placeholder="Je e-mail"
                                    className="create-comment__input"
                                    value={commentEmail || ''}
                                />
                            </div>

                            <div className="create-comment__value-container">
                                <label
                                    htmlFor="comment-reaction"
                                    className="create-comment__label"
                                >
                                    Je idee of praktijkvoorbeeld:
                                </label>
                                <textarea
                                    id="comment-reaction"
                                    onChange={(t) =>
                                        setNewComment(t.target.value)
                                    }
                                    placeholder="Je idee of voorbeeld..."
                                    className="create-comment__input create-comment__input--textarea"
                                    value={newComment || ''}
                                />
                            </div>
                            <button
                                className="create-comment__post-comment"
                                onClick={() => setShowCommentModal(true)}
                            >
                                Delen
                            </button>
                        </div>

                        <div className="comment-section__seperator"></div>
                        <div className="comment-section__comment-list">
                            {comments?.length ? (
                                <CommentList comments={comments} />
                            ) : (
                                <div className="comment-section__message">
                                    Nog geen ideeën of praktijkvoorbeelden
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="comment-section__message">
                        Ideeën en praktijkvoorbeelden zijn uitgeschakeld voor
                        deze kaart
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardDetail;

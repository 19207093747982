import React from 'react';
import CardThumbnail from './CardThumbnail';

const CardList = ({ cards }) => {
    return (
        <div className="card-list">
            {/*Checking if filter cards is an array with at least 1 element. Followed by iterating and rendering component*/}
            {cards?.length
                ? cards.map((card, i) => <CardThumbnail key={`card-${i}-${card?.id}`} card={card} />)
                : 'Geen kaarten gevonden'}
        </div>
    );
};

export default CardList;

import React, { useEffect, useState } from 'react';
import { likeCommentById } from '../config/firestore';
import Comment from './Comment';

const fetchLikesFromStorage = () => {
    let storage = localStorage.getItem('commentLikes');
    storage =
        storage && storage !== 'undefined' ? JSON.parse(storage) : storage;
    return storage?.length ? storage : [];
};

export const CommentList = ({ comments }) => {
    const [userLikes, setUserLikes] = useState(fetchLikesFromStorage());

    const likeComment = ({ id, likes }) => {
        let newLikeCount = likes;
        if (userLikes.includes(id)) {
            setUserLikes((prev) =>
                userLikes.filter((commentId) => commentId !== id)
            );
            newLikeCount--;
        } else {
            setUserLikes((prev) => {
                return userLikes?.length ? [...userLikes, id] : [id];
            });
            newLikeCount++;
        }
        likeCommentById(id, newLikeCount);
        return newLikeCount;
    };

    useEffect(() => {
        localStorage.setItem('commentLikes', JSON.stringify(userLikes));
    }, [userLikes]);

    return (
        <>
            {comments.map((comment, key) => (
                <Comment
                    comment={comment.data}
                    id={comment.id}
                    key={comment.id}
                    onLike={likeComment}
                    userLiked={
                        !!userLikes.find((liked) => liked === comment.id)
                    }
                />
            ))}
        </>
    );
};

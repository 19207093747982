import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CardList from '../components/CardList';
import SearchBar from '../components/SearchBar';
import SeriesCheckboxes, {
    SeriesCheckboxConfig,
} from '../components/SeriesCheckboxes';
import { getCards } from '../config/firestore';

const Cards = () => {
    const { search } = useLocation();
    const [searchQuery, setSearchQuery] = useState();
    // const [changedSearchQuery, setChangedSearchQuery] = useState();
    const [seriesQuery, setSeriesQuery] = useState();
    const [cards, setCards] = useState();
    const [filteredCards, setFilteredCards] = useState();
    const history = useHistory();

    // Fetch cards from FireStore on initialization
    useEffect(() => {
        fetchCards();
    }, []);

    // Save query in state
    useEffect(() => {
        const newSearchQuery = new URLSearchParams(search).get('search') || '';
        setSearchQuery(newSearchQuery);

        const newSeriesQuery = new URLSearchParams(search).get('series') || '';
        setSeriesQuery(newSeriesQuery);
    }, [search]);

    // Update filtered cards every time the queries change
    useEffect(() => {
        if (!cards?.length) return;
        const allSeriesValues = SeriesCheckboxConfig.map(
            (series) => series.name
        );
        let allSeriesInSeriesQuery = true;
        allSeriesValues.forEach((value) => {
            allSeriesInSeriesQuery = seriesQuery?.includes?.(value)
                ? allSeriesInSeriesQuery
                : false;
        });
        const showFromAllSeries = !seriesQuery || allSeriesInSeriesQuery;
        setFilteredCards(
            cards.filter((card) => {
                const isInTitle = card?.data?.title
                    ?.toLowerCase?.()
                    .includes?.(searchQuery?.toLowerCase?.());
                const isInContent = card?.data?.content
                    ?.toLowerCase?.()
                    .includes?.(searchQuery?.toLowerCase?.());
                const inSeries =
                    showFromAllSeries ||
                    (card?.data?.series &&
                        seriesQuery.includes(card?.data?.series));
                return (isInTitle || isInContent) && inSeries;
            })
        );
    }, [searchQuery, seriesQuery, cards]);

    // Fetching cards from FireStore and after awaiting, pushing them to state
    const fetchCards = async () => {
        const data = await getCards();
        const cardArr = [];
        data.forEach((card) =>
            cardArr.push({ data: card.data(), id: card.id })
        );
        setCards(cardArr);
    };

    const handleQueryUpdate = ({ search, series }) => {
        const searchQ = typeof search === 'string' ? search : searchQuery;
        const seriesQ = typeof series === 'string' ? series : seriesQuery;

        const query = [
            { key: 'search', value: searchQ },
            { key: 'series', value: seriesQ },
        ]
            .filter((q) => !!q.value)
            .map((q) => `${q.key}=${q.value}`)
            .join('&');

        history.push(`/cards${query ? `?${query}` : ''}`);
    };

    // Update search query on search submit
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        handleQueryUpdate({ search: event.target.search.value });
    };

    const handleSeriesChange = (values) => {
        handleQueryUpdate({ series: values?.join?.(',') });
    };

    return (
        <div className="cards-page">
            <div className="cards-page__content">
                <section className="cards-page__top">
                    <h1 className="cards-page__title">Kaarten</h1>
                    <SearchBar
                        defaultValue={searchQuery}
                        onSubmit={handleSearchSubmit}
                        buttonText="Zoek kaarten"
                        baseURL="/cards"
                    />
                    <SeriesCheckboxes
                        label="Toon kaarten van:"
                        className="cards-page__series"
                        onChange={handleSeriesChange}
                    />
                </section>
                <section className="cards-page__card-list">
                    <CardList cards={filteredCards} />
                </section>
            </div>
        </div>
    );
};
export default Cards;

import React, {useState, useEffect} from 'react'
import {
    getCommentConfirmationText,
    getHomePageDescription,
    updateCommentConfirmationText,
    updateHomePageDescription,
    uploadMP4,
    uploadPDF,
} from '../config/firestore';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

//TODO Add some confirmation of upload (content, pdf AND video)

const EditHome = () => {

    const [text, setText] = useState()

    // confirmations
    const [updatedMessage, setUpdatedMessage] = useState()

    useEffect(async () => {
        const response = await getCommentConfirmationText()
        const data = response.data()
        setText(data.content)
    },[])

    const updateText = async () => {
        updateCommentConfirmationText(text).then(res => setUpdatedMessage('Tekst opgeslagen!')).catch(err => setUpdatedMessage('Oeps! Er ging iets mis.'))
    }

    return(
        <div className='edit-home-wrapper'>
            <h2 className='edit-home-title'>Bevestigingstekst voor delen aanpassen</h2>
            <div className='edit-home-container'>
                <h4 className='edit-home-content-ta-label'>Uitleg</h4>
                <CKEditor
                    editor={ ClassicEditor }
                    data={text}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setText(data)
                    } }
                />
                <div className='edit-home-save-flex-container'>
                    <button className='edit-home-save' onClick={updateText}>{updatedMessage ? updatedMessage : 'Opslaan'}</button>
                </div>
            </div>
        </div>
    )
}
export default EditHome

import React, { useState } from 'react';
import { login } from '../config/firestore';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();

    const loginHandler = (event) => {
        event.preventDefault();

        login(email, password)
            .then((res) => {
                localStorage.setItem('token', res.user.refreshToken);
                window.location = '/dashboard';
            })
            .catch((err) =>
                setError('E-mailadres en/of wachtwoord is onjuist.')
            );
    };

    return (
        <div className="login-page">
            <form className="login-form" onSubmit={loginHandler}>
                <h2>Administrator login</h2>
                {error && <div className="auth-error-message">{error}</div>}
                <label className="auth-form-label">
                    E-mailadres
                    <input
                        id="login-email-id"
                        type="email"
                        className="login-email-field"
                        placeholder="janedoe@potentialtoteach.be"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <label className="auth-form-label">
                    Wachtwoord
                    <input
                        id="login-password-id"
                        type="password"
                        className="login-password-field"
                        placeholder="wachtwoord"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <button className="login-button" onClick={loginHandler}>
                    Login
                </button>
            </form>
        </div>
    );
};
export default SignIn;

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';

const CTAButton = ({
    className,
    text,
    to,
    onClick,
    withArrow,
    isSmall,
    ...props
}) => {
    const history = useHistory();

    const handleClick = (event) => {
        if (to) {
            event.preventDefault();
            history.push(to);
        }

        if (onClick instanceof Function) onClick(event);
    };

    return (
        <button
            className={`cta-button ${isSmall ? 'cta-button--small' : ''} ${
                className || ''
            }`}
            onClick={handleClick}
            {...props}
        >
            <div className="cta-button__text">{text}</div>{' '}
            {withArrow && <FontAwesomeIcon icon={faChevronRight} size="xs" />}
        </button>
    );
};

export default CTAButton;

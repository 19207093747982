import React, { useEffect, useState } from 'react';
import {
    getCardById,
    getUrlById,
    updateCardById,
    deleteCardById,
} from '../config/firestore';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SeriesCheckboxes from '../components/SeriesCheckboxes';
import Checkbox from '../components/Checkbox';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const EditCard = (props) => {
    const [card, setCard] = useState();
    const [title, setTitle] = useState();
    const [html, setHtml] = useState('');
    const [cardImage, setCardImage] = useState();
    const [cardImageSrc, setCardImageSrc] = useState();
    const [feedback, setFeedback] = useState();
    const [series, setSeries] = useState('');
    const [showComments, setShowComments] = useState(true);
    const [showModal, setShowModal] = useState(false); // Enkel voor edit card
    const history = useHistory();

    function onChangeContent(e) {
        setHtml(e.target.value);
    }

    useEffect(() => {
        if (!cardImage) {
            setCardImageSrc(null);
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            setCardImageSrc(event.target.result);
        };
        reader.readAsDataURL(cardImage);
    }, [cardImage]);

    const cardImageSelection = (e) => setCardImage(e.target.files[0]);

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    useEffect(() => {
        fetchCard();
    }, []);

    const fetchCard = async () => {
        const data = await getCardById(props.match.params.id);
        setCard(data.data());
        setHtml(data.data().content);
        setTitle(data.data().title);
        setSeries(data.data().series);
        setShowComments(data.data().commentsEnabled);

        setCardImageSrc(await getUrlById(props.match.params.id));
    };

    const updateCard = async () => {
        await updateCardById(
            props.match.params.id,
            title,
            html,
            series,
            showComments
        );

        history.push('/dashboard');
    };

    const handleDeleteCard = () => {
        deleteCardById(props.match.params.id);
        setShowModal(false);
        history.push('/dashboard');
    };

    return (
        <div className="edit-home-wrapper">
            {showModal && (
                <div className="deletion-modal">
                    <p className="deletion-modal-text">
                        {'Weet u zeker dat u deze kaart wil verwijderen?'}
                    </p>
                    <div className="deletion-modal-button-group">
                        <button
                            className="deletion-modal-button deletion-modal-confirm"
                            onClick={handleDeleteCard}
                        >
                            Verwijderen
                        </button>
                        <button
                            className="deletion-modal-button deletion-modal-cancel"
                            onClick={() => setShowModal(false)}
                        >
                            Annuleren
                        </button>
                    </div>
                </div>
            )}

            <h2 className="edit-home-title">Kaart bewerken</h2>
            <div className="edit-home-container create-card">
                <div className="create-card__left">
                    <h4>Titel</h4>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <h4 className="edit-home-content-ta-label">
                        Meer over dit onderwerp
                    </h4>
                    <CKEditor
                        editor={ClassicEditor}
                        data={html}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setHtml(data);
                        }}
                    />
                    <h4>Reeks</h4>
                    <SeriesCheckboxes
                        onChange={(event) => {
                            if (!event?.[0]) return;
                            setSeries(event[0]);
                        }}
                        isRadio
                        defaultValue={series ? [series] : null}
                    />
                    <h4>Reacties</h4>
                    <Checkbox
                        label="Toon reacties"
                        defaultChecked={showComments}
                        onChange={(event) => setShowComments(event.checked)}
                    />
                </div>
                <div className="create-card__right">
                    <h4>Afbeelding</h4>
                    <div className="create-card__file-input-container">
                        <input
                            type="file"
                            id="ccii"
                            className="file-input file-input-pdf create-card__file-input"
                            onChange={(e) => cardImageSelection(e)}
                        />
                        {cardImageSrc ? (
                            <img
                                src={cardImageSrc}
                                className="create-card__file-input-image"
                            />
                        ) : (
                            <label
                                htmlFor="ccii"
                                className="create-card__file-input-label"
                            >
                                Upload de afbeelding van de kaart
                            </label>
                        )}
                    </div>
                    {feedback && <h4>{feedback}</h4>}

                    <div className="edit-home-save-flex-container">
                        <button
                            className="edit-home-delete"
                            onClick={() => setShowModal(true)}
                        >
                            <FontAwesomeIcon
                                className="delete-icon"
                                icon={faTrashAlt}
                            />
                            <div>Verwijder deze kaart</div>
                        </button>
                        <button className="edit-home-save" onClick={updateCard}>
                            Opslaan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        // <div className='card-detail-container'>
        //     <div className='full-card edit-card'>
        //         <h1>Kaart bewerken</h1>
        //         <input className="edit-title" type='text' value={title} onChange={onChangeTitle} />
        //
        //         {card && <CKEditor
        //             editor={ ClassicEditor }
        //             data={card.content}
        //             onChange={ ( event, editor ) => {
        //                 const data = editor.getData();
        //                 setHtml(data)
        //             } }
        //         />}
        //         {/*<Select*/}
        //         {/*    value={selectedOption}*/}
        //         {/*    onChange={() => changeBackColor}*/}
        //         {/*    options={backgroundColor}*/}
        //         {/*/>*/}
        //
        //         <button className="update-card-button" onClick={updateCard}>Bewaren</button>
        //     </div>
        // </div>
    );
};
export default EditCard;

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

const Checkbox = ({
    defaultChecked,
    label,
    name,
    className,
    onChange,
    checked,
    ...props
}) => {
    const [isChecked, setIsChecked] = useState(!!defaultChecked);
    const inputRef = useRef(null);

    useEffect(() => {
        setIsChecked(defaultChecked);
    }, [defaultChecked]);

    useEffect(() => {
        if (typeof checked === 'undefined') return;
        setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
        if (!inputRef?.current) return;
        if (onChange instanceof Function) {
            onChange({
                value: inputRef.current.value,
                checked: inputRef.current.checked,
            });
        }
    }, [isChecked]);

    const handleClick = (event) => {
        event.stopPropagation();
        if (!inputRef?.current) return;
        setIsChecked((prev) => !prev);
    };

    return (
        <div
            className={`checkbox ${className || ''}`}
            onClick={handleClick}
            {...props}
        >
            <input
                ref={inputRef}
                className="checkbox__input"
                type="checkbox"
                name={name}
                id={name}
                value={name}
                checked={isChecked}
                readOnly
            />
            <div className="checkbox__box">
                {isChecked && (
                    <FontAwesomeIcon
                        className="checkbox__checkmark"
                        icon={faCheck}
                        size="lg"
                    />
                )}
            </div>
            {label && <div className="checkbox__label">{label}</div>}
        </div>
    );
};

export default Checkbox;

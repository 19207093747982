import React, { useEffect, useState } from 'react';
import { DefaultEditor } from 'react-simple-wysiwyg';
import {
    addCard,
    createCardId,
    uploadCardImage,
    uploadPDF,
} from '../config/firestore';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SeriesCheckboxes from '../components/SeriesCheckboxes';
import Checkbox from '../components/Checkbox';
import CTAButton from '../components/CTAButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';

const CreateCard = () => {
    const [html, setHtml] = useState('');
    const [title, setTitle] = useState('Zonder titel');
    const [cardImage, setCardImage] = useState();
    const [cardImageSrc, setCardImageSrc] = useState();
    const [feedback, setFeedback] = useState();
    const [series, setSeries] = useState('');
    const [showComments, setShowComments] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (!cardImage) {
            setCardImageSrc(null);
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            setCardImageSrc(event.target.result);
        };
        reader.readAsDataURL(cardImage);
    }, [cardImage]);

    const saveCard = async () => {
        console.log(title);
        console.log(html);
        console.log(series);
        console.log(showComments);
        try {
            const createdCard = await createCardId(
                title,
                html,
                series,
                showComments
            );
            const uploadResponse = await uploadCardImage(
                cardImage,
                createdCard.id
            );
            // setFeedback('Kaart toegevoegd!');
            history.push('/dashboard');
        } catch (error) {
            setFeedback('Oeps! Er ging iets mis.');
        }
    };

    const cardImageSelection = (e) => setCardImage(e.target.files[0]);

    return (
        <div className="edit-home-wrapper">
            <h2 className="edit-home-title">Nieuwe kaart toevoegen</h2>
            <div className="edit-home-container create-card">
                <div className="create-card__left">
                    <h4>Titel</h4>
                    <input
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <h4 className="edit-home-content-ta-label">
                        Meer over dit onderwerp
                    </h4>
                    <CKEditor
                        editor={ClassicEditor}
                        data={html}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setHtml(data);
                        }}
                    />
                    <h4>Reeks</h4>
                    <SeriesCheckboxes
                        onChange={(event) => {
                            if (!event[0]) return;
                            setSeries(event[0]);
                        }}
                        isRadio
                    />
                    <h4>Reacties</h4>
                    <Checkbox
                        label="Toon reacties"
                        defaultChecked={true}
                        onChange={(event) => setShowComments(event.checked)}
                    />
                </div>
                <div className="create-card__right">
                    <h4>Afbeelding</h4>
                    <div className="create-card__file-input-container">
                        <input
                            type="file"
                            id="ccii"
                            className="file-input file-input-pdf create-card__file-input"
                            onChange={(e) => cardImageSelection(e)}
                        />
                        {cardImageSrc ? (
                            <img
                                src={cardImageSrc}
                                className="create-card__file-input-image"
                            />
                        ) : (
                            <label
                                htmlFor="ccii"
                                className="create-card__file-input-label"
                            >
                                Upload de afbeelding van de kaart
                            </label>
                        )}
                    </div>
                    {feedback && <h4>{feedback}</h4>}

                    <div className="edit-home-save-flex-container">
                        <button className="edit-home-save" onClick={saveCard}>
                            Opslaan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CreateCard;

import React, {useState, useEffect} from 'react'
import {
    getHomePageDescription, getHomePageVideoURL,
    updateHomePageDescription,
    updateVideoUrl,
    uploadMP4,
    uploadPDF,
} from '../config/firestore';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

//TODO Add some confirmation of upload (content, pdf AND video)

const EditHome = () => {

    const [description, setDescription] = useState()
    const [pdf, setPdf] = useState()
    const [videoUrl, setVideoUrl] = useState()

    // confirmations
    const [contentMessage, setContentMessage] = useState()
    const [pdfMessage, setPdfMessage] = useState()
    const [videoMessage, setVideoMessage] = useState()

    useEffect(async () => {
        const response = await getHomePageDescription()
        const videoRes = await getHomePageVideoURL()
        const data = response.data()
        const videoData = videoRes.data()
        setDescription(data.content)
        setVideoUrl(videoData.videoUrl)
    },[])

    const updateDescription = async () => {
        updateHomePageDescription(description).then(res => setContentMessage('Tekst opgeslagen!')).catch(err => setContentMessage('Oeps! Er ging iets mis.'))
    }

    const videoUpdate = () => {
        setVideoMessage('Even geduld...')
        updateVideoUrl(videoUrl).then(res => setVideoMessage('Video-url opgeslagen!')).catch(err => setVideoMessage('Oeps! Er ging iets mis.'))
    }

    const pdfSelection = (e) => setPdf(e.target.files[0])
    const pdfUpload = () => {
        setPdfMessage('Even geduld...')
        uploadPDF(pdf).then(res => setPdfMessage('PDF opgeslagen!')).catch(err => setPdfMessage('Oeps! Er ging iets mis.'))
    }

    return(
        <div className='edit-home-wrapper'>
            <h2 className='edit-home-title'>Homepagina aanpassen</h2>
            <div className='edit-home-container'>
                <h4 className='edit-home-content-ta-label'>Uitleg</h4>
                <CKEditor
                    editor={ ClassicEditor }
                    data={description}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setDescription(data)
                    } }
                />

                <div className='edit-home-save-flex-container edit-home-content-btn-cont'>
                    <button className='edit-home-save' onClick={updateDescription}>{contentMessage ? contentMessage : 'Opslaan'}</button>
                </div>

                <h4 className='edit-home-pdf-label'>PDF</h4>
                <input type='file' className="file-input file-input-pdf" onChange={(e) => pdfSelection(e)} />

                <div className='edit-home-save-flex-container'>
                    <button className='edit-home-save' onClick={pdfUpload}>{pdfMessage ? pdfMessage : 'Upload PDF'}</button>
                </div>

                <h4 className='edit-home-video-label'>Video-url</h4>
                <input type='text' className="file-input file-input-pdf video-url-input" value={videoUrl?videoUrl:''} onChange={(e) => setVideoUrl(e.target.value)} />

                <div className='edit-home-save-flex-container'>
                    <button className='edit-home-save' onClick={videoUpdate}>{videoMessage ? videoMessage : 'Video-url bijwerken'}</button>
                </div>
            </div>
        </div>
    )
}
export default EditHome

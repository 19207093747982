import React, { useEffect, useState } from 'react';
import { getCards, getFilteredCards } from '../config/firestore';
import AdminCard from '../components/AdminCard';
import { Link, useHistory } from 'react-router-dom';
import SeriesCheckboxes from '../components/SeriesCheckboxes';

const Dashboard = () => {

    const [cards, setCards] = useState();
    const [series, setSeries] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            fetchCards();
        } else {
            window.location = '/signin'
        }
    }, []);

    useEffect(() => {
        const filteredArr = []
        if(series.length) {
            getFilteredCards(series).then(res => {
                res.forEach(card => filteredArr.push({ data: card.data(), id: card.id }))
                setCards(filteredArr)
            })
        }else{
            fetchCards()
        }
    }, [series])

    const fetchCards = async () => {
        const data = await getCards();
        const cardArr = [];
        data.forEach(card => cardArr.push({ data: card.data(), id: card.id }));
        setCards(cardArr);
        console.log(cardArr);
    };

    const logOut = () => {
        localStorage.removeItem('token')
        history.push('/')
    }

    const handleSeriesChange = (values) => setSeries(values)

    return (
        <div className='dashboard-container'>
            <h1 className='dashboard-title'>Dashboard</h1>
            <div className='edit-home-btn-logout-container'>
                <div className='edit-home-btn-title-container'>
                    <h3 className='home-dash-section-title'>Homepagina</h3>
                    <Link to='/dashboard/edit-home' className='edit-home-page-link'>Bewerk content van homepagina</Link>
                </div>
                <button className='logout-button' onClick={logOut}>Uitloggen</button>
            </div>

            {/*Edit comment-confirm message*/}
            <div className='edit-comment-confirm-container'>
                <h3 className='home-dash-section-title-comment'>Commentaar-systeem</h3>
                <Link to='/dashboard/edit-comment-text' className='edit-home-page-link'>Bewerk bevestigingstekst voor
                    delen</Link>
                <Link to='/dashboard/comments' className='edit-home-page-link'>Bewerk alle commentaren</Link>
            </div>

            <div className='card-list-admin'>
                <div className='dashboard-cards-section-title-add-btn'>
                    <h3>Kaarten</h3>
                    <Link to="/add"><button className='add-card-link'>Nieuwe kaart</button></Link>
                </div>
                <SeriesCheckboxes
                    label="Toon kaarten van:"
                    className="cards-page__series"
                    onChange={handleSeriesChange}
                />
                {cards && cards.map((card, key) => <AdminCard card={card} key={key} />)}
            </div>
        </div>
    );
};
export default Dashboard;

import React, {useState} from "react";
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { deleteCardById } from '../config/firestore';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';

const AdminCard = (props) => {

    const [showModal, setShowModal] = useState(false)

    const test = (id) => console.log(id)

    const deleteCard = () => {
        deleteCardById(props.card.id).then(res => window.location = '/dashboard')
    }

    return (
        <div className="full-card admin-card-override">
            {showModal &&
            <div className='deletion-modal'>
                <p className='deletion-modal-text'>{"Weet u zeker dat u '" + props.card.data.title + "' wil verwijderen?"}</p>
                <div className='deletion-modal-button-group'>
                    <button className='deletion-modal-button deletion-modal-confirm' onClick={deleteCard}>Verwijderen</button>
                    <button className='deletion-modal-button deletion-modal-cancel' onClick={() => setShowModal(false)}>Annuleren</button>
                </div>
            </div>
            }
            <div className='full-card-content-container'>
                <h2 className="dashboard-card-thumb-title">{props.card.data.title}</h2>

                <div className='dashboard-card-thumb-all-but-title'>
                    <p className="dashboard-card-series-label">Reeks <span className="dashboard-card-series-value">{props.card.data.series}</span></p>

                    <div className='dashboard-card-like-comment-div'>
                        <div className='dashboard-card-thumb-like-container'>
                            <FontAwesomeIcon className='like-icon' icon={faThumbsUp} />
                            <h3 className='dashboard-card-thumb-like-text'>{props.card.data.likes}</h3>
                        </div>
                    </div>

                    <div className='dashboard-card-controls-div'>
                        <Link to={"/edit/"+props.card.id}>
                            <FontAwesomeIcon className="dashboard-card-thumb-edit-icon edit" icon={faEdit} />
                        </Link>
                        <FontAwesomeIcon className="dashboard-card-thumb-edit-icon delete" icon={faTrashAlt} onClick={() => setShowModal(true)} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdminCard

import { useEffect, useState } from 'react';
import Checkbox from './Checkbox';

export const SeriesCheckboxConfig = [
    {
        name: 'intro',
        label: 'Introductiekaarten',
    },
    {
        name: 'a',
        label: 'Reeks A: Diversiteit waarderen en benutten in de klaspraktijk',
    },
    {
        name: 'b',
        label: 'Reeks B: Samenwerken met leerlingen en ouders ',
    },
    {
        name: 'c',
        label:
            'Reeks C: Samenwerken met collega’s en andere partners in en rondom de school',
    },
];

const SeriesCheckboxes = ({
    label,
    className,
    onChange,
    isRadio,
    defaultValue,
}) => {
    const [checkedValues, setCheckedValues] = useState([]);

    useEffect(() => {
        if (!defaultValue) return;
        setCheckedValues(defaultValue);
    }, [defaultValue]);

    const handleCheckBoxChange = ({ value, checked }) => {
        if (checked && !checkedValues.find((checkbox) => checkbox === value)) {
            setCheckedValues((prev) => (isRadio ? [value] : [...prev, value]));
        } else if (!checked) {
            setCheckedValues((prev) =>
                prev.filter((checkbox) => checkbox !== value)
            );
        }
    };

    useEffect(() => {
        if (onChange instanceof Function) onChange(checkedValues);
    }, [checkedValues]);

    return (
        <div className={`series-checkboxes ${className || ''}`}>
            {label && <h3 className="series-checkboxes__label">{label}</h3>}
            <div className="series-checkboxes__checkboxes">
                {SeriesCheckboxConfig?.map?.((checkbox, i) => {
                    const isInCheckedValues = !!checkedValues?.find?.(
                        (checkedValue) => checkedValue === checkbox?.name
                    );
                    return (
                        <Checkbox
                            key={`series-checkbox-${i}`}
                            onChange={handleCheckBoxChange}
                            // onClick={event => event.stopPropagation()}
                            checked={isInCheckedValues}
                            {...checkbox}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SeriesCheckboxes;

import React, { useEffect, useState } from 'react';
import CTAButton from '../components/CTAButton';
import {
    getHomePageDescription,
    getHomePageVideoURL,
    getPDF,
} from '../config/firestore';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    const [description, setDescription] = useState();
    const [cardsURL, setCardsURL] = useState();
    const [videoURL, setVideoURL] = useState();

    useEffect(() => {
        getHomePageDescription().then((data) => {
            if (data.exists) {
                setDescription((prev) => data.data()?.content || prev);
            }
        });

        getHomePageVideoURL().then((data) => {
            if (data.exists) {
                console.log();
                setVideoURL((prev) => data.data()?.videoUrl || prev);
            }
        });

        getPDF().then((data) => {
            setCardsURL((prev) => data || prev);
        });
    }, []);

    return (
        <div className="home">
            <div className="home__content">
                <h1 className="home__title">
                    Gespreksstarters over diversiteit en inclusie
                </h1>
                {videoURL && (
                    <section className="home__video-section">
                        <ReactPlayer
                            url={videoURL}
                            controls
                            width="100%"
                            height="100%"
                        />
                    </section>
                )}
                <section className="home__explanation">
                    <div
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                </section>
                <section className="home__cards">
                    <div className="home__card-ctas">
                        <CTAButton
                            className="home__all-cards"
                            text="Bekijk kaarten"
                            to="/cards"
                            withArrow
                        />
                        {cardsURL && (
                            <>
                                <span className="home__or">of</span>
                                <CTAButton
                                    className="home__download-cards"
                                    text="Download alle kaarten"
                                    onClick={() => window.open(cardsURL)}
                                />
                            </>
                        )}
                        <span className="home__or">of</span>
                        <a className="a-no-td" href="https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fforms.office.com%2Fr%2FzCRyfS0q28&data=04%7C01%7Cjonas.stasseyns%40student.arteveldehs.be%7Ca9e46b7447c24623b55f08d8f2ac216b%7Cb6e080eaadb94c7993036dcf826fb854%7C0%7C0%7C637526168405617813%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=2m7txJVd2DeA1tW3ehThBbUf32FuoV5vqgf4Z91S9kk%3D&reserved=">
                        <button
                            className="cta-button">
                            Bestel de doos
                            &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faChevronRight} size="xs" />
                        </button>
                        </a>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Home;

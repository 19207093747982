import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as faLikeSolid } from '@fortawesome/free-solid-svg-icons';
import { likeCardById, likeCommentById } from '../config/firestore';

const Comment = ({
    comment: { user, content, likes },
    id,
    onLike,
    userLiked,
}) => {
    const [likeCount, setLikeCount] = useState(Number.isNaN(likes) ? 0 : likes);

    const handelLike = () => {
        if (onLike instanceof Function) {
            const newLikeCount = onLike({ id, likes: likeCount });
            setLikeCount(newLikeCount);
        }
    };

    return (
        <div className="comment">
            <h3 className="comment__username">{user}</h3>
            <p
                className="comment__content"
                dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="comment__likes-row">
                <FontAwesomeIcon
                    className="like-icon"
                    icon={userLiked ? faLikeSolid : faThumbsUp}
                    onClick={handelLike}
                />
                <div className="comment__like-count">{likeCount}</div>
            </div>
        </div>
    );
};
export default Comment;

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const storage = firebase.storage().ref();
const auth = firebase.auth();

// Saving a new comment to FireStore
export const storeComment = (userName, email, content, cardId) => {
    return db.collection('comments').add({
        cardId: cardId,
        user: userName,
        content: content,
        email: email,
        likes: 0,
    });
};

// Fetching all cards from FireStore
export const getCards = () => {
    return db.collection('cards').get();
};

export const getComments = () => {
    return db.collection('comments').get();
};

export const deleteCommentById = (id) => {
    return db.collection('comments').doc(id).delete();
};

export const getCardById = (id) => {
    return db.collection('cards').doc(id).get();
};

export const getHomePageDescription = (id) => {
    return db.collection('content').doc('homepagedesc').get();
};

export const updateHomePageDescription = (content) => {
    return db.collection('content').doc('homepagedesc').update({
        content,
    });
};

export const getHomePageVideoURL = () => {
    return db.collection('content').doc('homepagevideo').get();
};

export const updateVideoUrl = (url) => {
    return db.collection('content').doc('homepagevideo').update({
        videoUrl: url,
    });
};

export const deleteCardById = (cardId) => {
    storage.child('cards/' + cardId + '.png').delete();
    return db.collection('cards').doc(cardId).delete();
};

export const updateCardById = (id, title, content, series, commentsEnabled) => {
    return db.collection('cards').doc(id).update({
        title,
        content,
        series,
        commentsEnabled,
    });
};

export const likeCardById = (id, likeCount) => {
    return db.collection('cards').doc(id).update({
        likes: likeCount,
    });
};

export const likeCommentById = (id, likeCount) => {
    return db.collection('comments').doc(id).update({
        likes: likeCount,
    });
};

export const getCommentsByCardId = (id) => {
    return db.collection('comments').where('cardId', '==', id.toString()).get();
};

export const createCardId = (title, content, series, commentsEnabled) => {
    return db.collection('cards').add({
        title,
        content,
        series,
        commentsEnabled,
        likes: 0,
    });
};

// Dashboard series filtering
export const getFilteredCards = (series) => {
    return db.collection('cards').where('series', 'in', series).get();
};

// Comment confirmation modal text
export const getCommentConfirmationText = () => {
    return db.collection('content').doc('commentconfirmation').get();
};

export const updateCommentConfirmationText = (text) => {
    return db.collection('content').doc('commentconfirmation').update({
        text,
    });
};

// Storage
export const uploadCardImage = (card, id) => {
    const ref = storage.child('cards/' + id + '.png');
    return ref.put(card);
};

export const getUrlById = (id) => {
    const ref = storage.child('cards/' + id + '.png');
    return ref.getDownloadURL();
};

export const uploadPDF = (pdf) => {
    const ref = storage.child('Gespreksstarters.pdf');
    return ref.put(pdf);
};

export const getPDF = () => {
    return storage.child('Gespreksstarters.pdf').getDownloadURL();
};

// export const uploadMP4 = (mp4) => {
//     const ref = storage.child('instructievideo.mp4');
//     return ref.put(mp4);
// };

// Auth
export const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
};

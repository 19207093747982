import React from 'react';
import CTAButton from './CTAButton';

const SearchBar = ({ className, onSubmit, onChange, buttonText, baseURL, ...props }) => {
    return (
        <form
            action={baseURL}
            className={`search-bar ${className || ''}`}
            onSubmit={onSubmit}
        >
            <input
                type="text"
                name="search"
                id="search"
                placeholder="Zoekterm"
                className="search-bar__field"
                onChange={onChange}
                {...props}
            />
            <CTAButton type="submit" text={buttonText} withArrow />
        </form>
    );
};

export default SearchBar;

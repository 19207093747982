import React, { useState, useEffect } from 'react';
import { deleteCommentById, getCards, getComments } from '../config/firestore';
import { Link, useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import CTAButton from '../components/CTAButton';

const AdminCommentList = () => {
    const { search } = useLocation();
    const [comments, setComments] = useState(false);
    const [searchQuery, setSearchQuery] = useState();
    const [filteredComments, setFilteredComments] = useState();
    const [
        showDeletionConfirmationModal,
        setShowDeletionConfirmationModal,
    ] = useState(false);

    const fetchComments = async () => {
        const data = await getComments();
        const commentArr = [];
        data.forEach((card) => {
            commentArr.push({ data: card.data(), id: card.id });
        });
        setComments(commentArr);
    };

    useEffect(fetchComments, []);

    // Save query in state
    useEffect(() => {
        const newSearchQuery = new URLSearchParams(search).get('search') || '';
        setSearchQuery(newSearchQuery);
    }, [search]);

    // Update filtered cards every time the query changes
    useEffect(() => {
        if (!comments?.length) return;
        setFilteredComments(
            comments.filter((comment) => {
                const inUsername = comment?.data?.user
                    ?.toLowerCase?.()
                    .includes?.(searchQuery?.toLowerCase?.());
                const inComment = comment?.data?.content
                    ?.toLowerCase?.()
                    .includes?.(searchQuery?.toLowerCase?.());
                const inEmail = comment?.data?.email
                    ?.toLowerCase?.()
                    .includes?.(searchQuery?.toLowerCase?.());
                // TODO: email checken

                return inUsername || inComment;
            })
        );
    }, [searchQuery, comments]);

    const deleteComment = (id) => {
        if (!id) return;
        deleteCommentById(id);
        fetchComments();
        setShowDeletionConfirmationModal(false);
    };

    return (
        <div className="admin-comment">
            <h2 className="admin-comment-list-title">
                Ideeën en praktijkvoorbeelden
            </h2>

            <SearchBar
                className="admin-comment__search"
                baseURL=""
                buttonText="Zoek"
                defaultValue={searchQuery}
            />

            {showDeletionConfirmationModal && (
                <div className="deletion-modal">
                    <h4>Weet u zeker dat u deze reactie wil verwijderen?</h4>
                    <h5>
                        Eens deze verwijderd is, kan dit niet ongedaan gemaakt
                        worden.
                    </h5>
                    <div className="deletion-modal-button-group">
                        <button
                            className="deletion-modal-button deletion-modal-cancel"
                            onClick={() =>
                                setShowDeletionConfirmationModal(false)
                            }
                        >
                            Annuleren
                        </button>
                        <button
                            className="deletion-modal-button deletion-modal-confirm"
                            onClick={() =>
                                deleteComment(showDeletionConfirmationModal.id)
                            }
                        >
                            Definitief verwijderen
                        </button>
                    </div>
                </div>
            )}

            {filteredComments && filteredComments.length ? (
                <div className="admin-comment__list-container">
                    {filteredComments.map((comment, key) => (
                            <div className="admin-comment-container" key={key}>
                                <div className="admin-comment__top">
                                    {comment.data.user && (
                                        <div className="admin-comment__name">
                                            {comment.data.user}
                                        </div>
                                    )}
                                    {comment.data.email && (
                                        <div className="admin-comment__email">
                                            {comment.data.email}
                                        </div>
                                    )}
                                </div>
                                {comment.data.content && (
                                    <p className="admin-comment-reaction">
                                        {comment.data.content}
                                    </p>
                                )}
                                <div className="admin-comment__button-row">
                                    <div className="admin-comment__likes">
                                        <FontAwesomeIcon
                                            className="admin-comment__like-icon"
                                            icon={faThumbsUp}
                                            size="lg"
                                        />
                                        {comment.data.likes}
                                    </div>
                                    <CTAButton
                                        to={`/card/${comment.data.cardId}`}
                                        text="Bekijk kaart"
                                        isSmall
                                    />
                                    {/* <Link to={'/card/' + comment.data.cardId}>
                                <button className="admin-comment-delete">
                                    Bekijk kaart
                                </button>
                            </Link> */}
                                    <FontAwesomeIcon
                                        className="edit-icon delete-icon"
                                        icon={faTrashAlt}
                                        size="lg"
                                        onClick={() =>
                                            setShowDeletionConfirmationModal(
                                                comment
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                'Geen Ideeën of praktijkvoorbeelden gevonden'
            )}
        </div>
    );
};
export default AdminCommentList;
